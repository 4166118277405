import React, { useState } from "react";
import NavBar from "../../../Components/NavBar";
import { Row, Col, Container } from "react-bootstrap";
import DetailSummary from "../../../Components/DetailSummary";
import viddeo from "../../../../static/Images/howtocreate.mp4";
import "./HelpStyles.css";

const Help = () => {
  const faq = [
    {
      question: "What is the AutoBET or AutoBET Lottery Ecosystem?",
      solution:
        "AutoBET Lottery Ecosystem is an advanced platform that utilizes blockchain technology and smart contracts to provide a transparent and decentralized lottery system. It offers various advanced features such as pre-whitelabel lottery products, automated ticket purchases, fair and random draws, instant payouts, and a secure environment for participants.",
      id: 1,
    },
    {
      question: "How does the AutoBET Lottery Ecosystem work?",
      solution:
        "AutoBET Lottery Ecosystem operates on a decentralized blockchain network, ensuring transparency and fairness. Participants can not only purchase lottery tickets using supported cryptocurrencies but allows participants to create lotteries using the platform whitelablel products (B2C lottery solution). Smart contracts handle lottery creation, ticket purchases, random number generation, and prize distribution. The draw is conducted automatically, and winners receive instant payouts directly to their digital wallets.",
      id: 2,
    },
    {
      question: "How can I participate in the AutoBET Lottery?",
      solution:
        "To participate in the AutoBET Lottery, you need simply need to have a compatible digital wallet or create one over metamask or use web3 wallet and it's browser or google chrome Metamask plugins. Lottery creators are required to do a one-off registration on the platform with a 10% fee of the total fundraising volume. Players can purchase lottery tickets using supported cryptocurrencies, and your tickets will be automatically entered into the draw. Make sure to check the rules and regulations of your jurisdiction regarding participation in lotteries.",
      id: 3,
    },
    {
      question: "Which cryptocurrencies are accepted for ticket purchases?",
      solution:
        "AutoBET Lottery currently accepts POLYGON (POL) cryptocurrency. The platform aims to expand its supported cryptocurrencies in the future to provide more options for participants including implementation of it's native BET token.",
      id: 4,
    },
    {
      question: "How are winners selected in the AutoBET Lottery?",
      solution:
        "Winners in the AutoBET Lottery are randomly selected using a transparent and secure algorithm. The smart contracts generate random numbers based on predetermined factors, ensuring fairness and impartiality in the draw using the latest Chainlink VRF technology. The selection process is automated and cannot be influenced or manipulated by any party.",
      id: 5,
    },
    {
      question: "How are prizes distributed to winners?",
      solution:
        "Prizes are distributed instantly to the digital wallets of the winners. Upon the completion of the draw, the smart contracts automatically transfer the respective prize amounts (inclusive of 10% taxed by the platform) to the wallet addresses associated with the winning tickets. Winners can then access and manage their winnings as per their preference.",
      id: 6,
    },
    {
      question: "Is the AutoBET Lottery Ecosystem secure?",
      solution:
        "Yes, the AutoBET Lottery Ecosystem prioritizes security and utilizes blockchain technology to ensure a secure and tamper-proof environment. The decentralized nature of the platform, combined with smart contracts, enhances transparency and eliminates the possibility of fraud or manipulation. We have plans to engage Smart Contract Audits to be done regularly by our partners.",
      id: 7,
    },
    {
      question: "Can I play the AutoBET Lottery from any country?",
      solution:
        "The availability of the AutoBET Lottery may vary depending on the legal restrictions and regulations of each country. It is essential to check the platform's terms and conditions and comply with the laws of your jurisdiction before participating in the lottery.",
      id: 8,
    },
    {
      question: "How can I contact AutoBET Lottery Ecosystem support?",
      solution:
        "For any inquiries or assistance, you can reach out to AutoBET Lottery's customer support team through their official website. AutoBET team can bet contacted by the official email address info@autobetlottery.com where you can submit your questions or concerns. Response times may vary, but AutoBET team strives to address customer queries promptly.\nPlease note that the above FAQs provide general information. For specific details and the most up-to-date information, it's advisable to visit the official AutoBET Lottery website or consult their customer support.",
      id: 9,
    },
    {
      question: "Are there any additional fees or commissions?",
      solution:
        "AutoBET Lottery Ecosystem does not charge any fees to the players, except paying only network transaction fees. For the lottery players they only pay a 10% one off registration fee for the total volume that they want to fundraise. The fee structure and details can be found on the platform. It is important to review and understand the fee policy before participating in the lottery.",
      id: 10,
    },
    {
      question: "What are the advantages of using AutoBET Lottery?",
      type: "multiple",
      solution: [
        {
          subSolution: "AutoBET Lottery offers several advantages, including:",
        },
        {
          subTitle: "Transparency: ",
          subSolution:
            "All lottery transactions and drawings are recorded on the blockchain, ensuring transparency and eliminating any possibility of fraud.",
        },
        {
          subTitle: "Security: ",
          subSolution:
            " The use of blockchain technology provides a high level of security for participants' funds and personal information.",
        },
        {
          subTitle: "Automation: ",
          subSolution:
            "The entire lottery process, from ticket sales to the drawing of winning numbers, is automated through smart contracts, reducing the need for human intervention.",
        },

        {
          subTitle: "Instant payouts: ",
          subSolution:
            "Winners receive their prizes instantly in cryptocurrency, eliminating the delays associated with traditional lotteries.",
        },
        {
          subTitle: " Global accessibility: ",
          subSolution:
            " AutoBET Lottery is accessible to participants worldwide, breaking down geographical barriers and allowing anyone with an internet connection to participate. ",
        },
      ],
      id: 11,
    },
    {
      question: "Can I trust the AutoBET Lottery platform?",
      solution:
        "AutoBET Lottery is built on blockchain technology, which ensures the transparency and security of the platform. The use of smart contracts eliminates the need for trust in a centralized authority, as the entire lottery process is governed by code. However, as with any online platform, it's essential to take necessary precautions to secure your personal information and funds.",
      id: 12,
    },
    {
      question: "Can I remain anonymous if I win a prize on AutoBET Lottery?",
      solution:
        "AutoBET Lottery respects the privacy of its participants. However, the extent of anonymity may depend on local regulations and the specific cryptocurrency used. Some cryptocurrencies provide more privacy features than others. It's recommended to familiarize yourself with the privacy features of the chosen cryptocurrency and any legal obligations in your jurisdiction.",
      id: 13,
    },
    {
      question: "Can anyone participate in the AutoBET Lottery Ecosystem?",
      solution:
        "Yes, anyone who meets the legal age requirement can participate in the AutoBET Lottery Ecosystem. It is accessible to users worldwide, subject to the local regulations and restrictions regarding online gambling or lotteries.",
      id: 14,
    },
    {
      question: "What is AutoBET pre-whitelabel lottery branding?",
      solution:
        "It is a process of branding lottery products on AutoBET with customizing of its lottery products and it's associated ecosystem products through intergration of partner's logo. AutoBET partners earn through lottery products that contains it's logo. Lottery creators nominated which partners to donate  a percentage of its profit to when creating a lottery.",
      id: 15,
    },
    {
      question: "How does AutoBET partnership work?",
      type: "multiple",
      solution: [
        {
          subSolution:
            "AutoBET's partnership program works by establishing collaborations with various organizations and entities that are approved by AutoBET to leverage their expertise, resources, and networks in the lottery and ecosystem space. Here's an overview of how the AutoBET partnership program functions:",
        },
        {
          subTitle: "Identification and Invitation: ",
          subSolution:
            "AutoBET identifies potential partners based on specific criteria such as their alignment with AutoBET's goals, expertise in related fields, or their potential to contribute to the growth of the lottery ecosystem. Once identified, AutoBET extends an invitation to the prospective partners to explore a partnership opportunity.",
        },
        {
          subTitle: "Initial Discussions: ",
          subSolution:
            "AutoBET initiates discussions with interested partners to understand their goals, objectives, and potential areas of collaboration. These discussions aim to align the interests of both parties and explore how a partnership can mutually benefit each other.",
        },
        {
          subTitle: "Partnership Agreement: ",
          subSolution:
            "If both parties determine that a partnership is viable, a partnership agreement is drafted. The agreement outlines the terms and conditions of the partnership, including the scope of collaboration, responsibilities, intellectual property rights, financial arrangements, and any other relevant provisions.",
        },
        {
          subTitle: "Collaboration and Integration: ",
          subSolution:
            "Once the partnership agreement is in place, AutoBET and its partners work together to integrate their respective systems, technologies, and resources. This may involve technical integration, branding initiatives, joint marketing campaigns, or collaborative product development to enhance the lottery and ecosystem offerings.",
        },
        {
          subTitle: "Shared Benefits:",
          subSolution:
            "Through the partnership, both AutoBET and its partners share the benefits and successes achieved in the lottery ecosystem. This can include revenue sharing, mutual promotion, access to each other's user base, shared resources, and other forms of collaboration that align with the partnership agreement.",
        },
        {
          subTitle: "Ongoing Support and Communication: ",
          subSolution:
            "AutoBET maintains a close relationship with its partners through ongoing support and communication. Regular meetings, updates, and collaboration sessions ensure that both parties stay aligned and work towards achieving their shared objectives.",
        },
        {
          subTitle: "Evaluation and Expansion: ",
          subSolution:
            "AutoBET continuously evaluates the effectiveness of its partnerships to ensure they are delivering the desired results. If successful, AutoBET may explore opportunities to expand the partnership further or replicate successful partnership models with other organizations.",
        },
        {
          subSolution:
            "AutoBET's partnership program is designed to foster mutually beneficial relationships, leverage the strengths of its partners, and drive the growth and impact of its lottery and ecosystem products. By collaborating with a diverse range of organizations, AutoBET aims to create a robust and inclusive ecosystem that benefits all stakeholders involved.",
        },
      ],
      id: 16,
    },
    {
      question: "What are AutoBET Stakeholders?",
      type: "multiple",
      solution: [
        {
          subSolution:
            "The following are the Stakeholders of AutoBET Lottery Ecosystem?",
        },
        {
          subTitle: "Partners: ",
          subSolution:
            "our partners are any organization that supports web3 technology and the mass adoption of cryptocurrency and will help with the long term growth of AutoBET technology.",
        },
        {
          subTitle: "Creators: ",
          subSolution:
            "Creators are registered users on the AutoBET platform that meets the platform's requirement. Creators will create a lottery within the minimum and maximum price amount range and will deposit a winning amount of their lotteries.",
        },
        {
          subTitle: "Players: ",
          subSolution:
            "Users who play and wins the lotteries will be called as players.",
        },
      ],
      id: 17,
    },
    {
      question:
        "For partnership, which organization are eligible to participate as partners?",
      type: "multiple",
      solution: [
        {
          subTitle: "Charity: ",
          subSolution:
            "Charity organization that wants to collect donation through cryptocurrencies.",
        },
        {
          subTitle: "Free Academy: ",
          subSolution:
            "AutoBET supports Academy platform that aims to make education free for the world. It encourages education platforms that falls in that catergories to express interest for partnership.",
        },
        {
          subTitle: "Web 3 Marketing Agencies:",
          subSolution:
            "As part of our growth strategy, AutoBET encourage marketing agencies or platforms for a partnership to promote it's business and make possible to earn automatically on AutoBET platform.",
        },
        {
          subTitle: "Web 3 Labs: ",
          subSolution:
            "AutoBET cares about advancing blockchain technology to a new level as we believe in it's associated benefits and making this world a better place with decentralized systems. We encourage blockchain based labs to partner up with AutoBET to intergrate on it's network and better products as they become available.",
        },
        {
          subTitle: "Startups: ",
          subSolution:
            "We encourage web3 startup platforms and launchpads to fundraise over AutoBET. AutoBET aims to provide an alternate way to fundraise over lottery and it's associated ecosystem products.",
        },
        {
          subTitle: "Crowdfunding Platforms: ",
          subSolution:
            "AutoBET encourages web3 crowdfunding platform and launchpads to partner up with it to make fundraising easier.",
        },

        {
          subTitle: "Fan Clubs: ",
          subSolution:
            "Sports club are offered a unique opportunity over AutoBET partnership program. A club can partnerup with AutoBET to make it's club fundraising easier with it's network of fans.",
        },
        {
          subTitle: "Free Travel: ",
          subSolution:
            "We support Travel Agencies or logistics organization that attempt to make transportation of  goods and services free. As such AutoBET invites business that support this vision",
        },
        {
          subTitle: "Angel Investor Clubs: ",
          subSolution:
            "We support Angel Investor group that looks at investing in the future technology and expansion of the blockchain and it's associated technology",
        },
      ],
      id: 18,
    },
  ];
  return (
    <div id="helpScreen">
      <NavBar />
      <Container>
        <h1
          className="title my-5"
          style={{ textAlign: "left", fontWeight: "600" }}
        >
          How to use AutoBET ?
        </h1>

        <Row className="mb-5">
          <Col className="mb-5 d-flex justify-content-center" md={6} lg={6}>
            <div>
              <iframe
                className="videoimg my-3 mt-lg-0"
                src="https://www.youtube.com/embed/LkDAwp3wv6Q?si=Eo0inhVWJ6b0Gy0s"
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p className="videotitle">
                <b>How to connect with POL mainnet</b>
              </p>
            </div>
          </Col>
          <Col className="mb-5 d-flex justify-content-center" md={6} lg={6}>
            <div>
              <iframe
                className="videoimg my-3 mt-lg-0"
                src="https://www.youtube.com/embed/s9uvPf6IWhA"
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p className="videotitle">
                <b>How to sign up as a creator</b>
              </p>
            </div>
          </Col>
          <Col className="mb-5 d-flex justify-content-center" md={6} lg={6}>
            <div>
              <iframe
                className="videoimg my-3 mt-lg-0"
                src="https://www.youtube.com/embed/kAAK7fPCeb4"
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p className="videotitle">
                <b>How to create a Revolver lottery</b>
              </p>
            </div>
          </Col>
          <Col className="mb-5 d-flex justify-content-center" md={6} lg={6}>
            <div>
              <iframe
                className="videoimg my-3 mt-lg-0"
                src="https://www.youtube.com/embed/eHe5smofJFw"
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p className="videotitle">
                <b>How to create a Mine lottery</b>
              </p>
            </div>
          </Col>
          <Col className="mb-5 d-flex justify-content-center" md={6} lg={6}>
            <div>
              <iframe
                className="videoimg my-3 mt-lg-0"
                src="https://www.youtube.com/embed/dKw-DDT88oM"
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p className="videotitle">
                <b>How to create a MRL lottery</b>
              </p>
            </div>
          </Col>
          <Col className="mb-5 d-flex justify-content-center" md={6} lg={6}>
            <div>
              <iframe
                className="videoimg my-3 mt-lg-0"
                src="https://www.youtube.com/embed/gR9xiGbp7gc"
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p className="videotitle">
                <b>How to create a Missile lottery</b>
              </p>
            </div>
          </Col>
          <Col className="mb-5 d-flex justify-content-center" md={6} lg={6}>
            <div>
              <iframe
                className="videoimg my-3 mt-lg-0"
                src="https://www.youtube.com/embed/QJ1RCxfFqh0"
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p className="videotitle">
                <b>How to buy a revolver lottery ticket</b>
              </p>
            </div>
          </Col>
          <Col className="mb-5 d-flex justify-content-center" md={6} lg={6}>
            <div>
              <iframe
                className="videoimg my-3 mt-lg-0"
                src="https://www.youtube.com/embed/B2k5l4loX9Y"
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p className="videotitle">
                <b>How to buy a Mine lottery ticket</b>
              </p>
            </div>
          </Col>
          <Col className="mb-5 d-flex justify-content-center" md={6} lg={6}>
            <div>
              <iframe
                className="videoimg my-3 mt-lg-0"
                src="https://www.youtube.com/embed/nBQEqTlpyfM"
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p className="videotitle">
                <b>How to buy a MRL lottery ticket</b>
              </p>
            </div>
          </Col>
          <Col className="mb-5 d-flex justify-content-center" md={6} lg={6}>
            <div>
              <iframe
                className="videoimg my-3 mt-lg-0"
                src="https://www.youtube.com/embed/evAlKTZb7l8"
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p className="videotitle">
                <b>How to buy a Missile lottery ticket</b>
              </p>
            </div>
          </Col>
          <Col className="mb-5 d-flex justify-content-center" md={6} lg={6}>
            <div>
              <iframe
                className="videoimg my-3 mt-lg-0"
                src="https://www.youtube.com/embed/OlNKPNWm7J0"
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p className="videotitle">
                <b>How to create a GiftCard</b>
              </p>
            </div>
          </Col>
          <Col className="mb-5 d-flex justify-content-center" md={6} lg={6}>
            <div>
              <iframe
                className="videoimg my-3 mt-lg-0"
                src="https://www.youtube.com/embed/ssMFfq9NEGc"
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p className="videotitle">
                <b>How to buy a GiftCard</b>
              </p>
            </div>
          </Col>
          <Col className="mb-5 d-flex justify-content-center" md={6} lg={6}>
            <div>
              <iframe
                className="videoimg my-3 mt-lg-0"
                src="https://www.youtube.com/embed/u0xohxHD6pU"
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p className="videotitle">
                <b>How to withdraw earnings.</b>
              </p>
            </div>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col lg={4} md={12} className="mb-5 mb-lg-0">
            <h1
              className="title mb-3"
              style={{ textAlign: "left", fontWeight: "600", lineHeight: 1.3 }}
            >
              Frequently Asked Questions
            </h1>
            <p>
              Haven't found the answers you are looking for? Contact us at{" "}
              <a href="mailto:info@autobetlottery.com">
                info@autobetlottery.com
              </a>
            </p>
          </Col>
          <Col lg={8} md={12} className="mb-5">
            {faq.map((data) => (
              <DetailSummary data={data} id={data.id} />
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Help;
